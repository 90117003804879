import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Stack } from 'grommet';

import PatternDisplay from './PatternDisplay';
import ProductBadges from '../Product/ProductBadges';

const WallcoveringsPatternGridItem = ({
  altProducts = [],
  product,
  pattern,
  imageSizes,
}) => {
  return (
    <Stack fill anchor="top-right">
      <PatternDisplay
        activeProduct={product}
        altProducts={altProducts}
        pattern={pattern}
        product={product}
        imageSizes={imageSizes}
      />
      <ProductBadges
        style={{
          pointerEvents: 'all',
          zIndex: 2,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
        tags={product.tags}
        product={product}
        margin="-20px"
        size="120px"
      />
    </Stack>
  );
};

WallcoveringsPatternGridItem.propTypes = {
  altProducts: PropTypes.array,
  product: PropTypes.object.isRequired,
  pattern: PropTypes.object.isRequired,
  imageSizes: PropTypes.string,
};

export default memo(WallcoveringsPatternGridItem);
