import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { graphql, navigate } from 'gatsby';

import WallcoveringsCollection from '../components/Wallcoverings/WallcoveringsCollection';
import Seo from '../components/Seo';
import deNodify from '../lib/deNodify';
import { useStoryblokState } from 'gatsby-source-storyblok';
import { get } from '../lib/nodash';

const WallcoveringsCollectionPage = ({
  data: {
    collection,
    patterns,
    site,
    colorTagGroup,
    filters = [],
    filterCollections = [],
  },
  pageContext: { collectionSlug, noIndex },
  location,
}) => {
  useEffect(() => {
    if (!collection) {
      navigate('/');
    }
  }, [collection]);
  if (!collection) return null;

  const filterStory = useStoryblokState(filters);
  const filterGroups = useMemo(
    () => get('content.filterGroups', filterStory) || [],
    [filters]
  );

  const colorFilters = useMemo(
    () => filterGroups.find(({ title }) => title === 'Color')?.filters ?? [],
    [filterGroups]
  );

  const seoTitle = collection?.seo?.title || collection?.title;
  const seoDescription =
    collection?.seo?.description || collection?.description;

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        canonical={`${site.siteMetadata.siteUrl}${location.pathname}`}
        image={collection?.image?.originalSrc}
        titleTemplate="%s | Backdrop x Schumacher"
        noIndex={noIndex}
      />
      <Router basepath={`/collections/${collectionSlug}`}>
        <WallcoveringsCollection
          path="*activeHandle"
          collection={collection}
          patterns={deNodify(patterns)}
          colors={colorTagGroup.tags}
          routeBase={`/collections/${collectionSlug}`}
          colorFilters={colorFilters ?? []}
          filterCollections={deNodify(filterCollections)}
        />
      </Router>
    </>
  );
};

WallcoveringsCollectionPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object,
  filters: PropTypes.array,
};

export default WallcoveringsCollectionPage;

export const query = graphql`
  query WallcoveringsCollectionPage($collectionSlug: String!) {
    collection: shopifyCollection(handle: { eq: $collectionSlug }) {
      title
      description
      descriptionHtml
      seo {
        description
        title
      }
      image {
        originalSrc
      }
      products {
        id
        shopifyId
        title
        tags
        handle
        designer: metafield(key: "name", namespace: "designer") {
          value
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        productType
        designer: metafield(key: "name", namespace: "designer") {
          value
        }
        media {
          ... on ShopifyMediaImage {
            image {
              originalSrc
            }
          }
        }
        featuredImage {
          originalSrc
        }
        description
        variants {
          id
          shopifyId
          title
          selectedOptions {
            name
            value
          }
        }
      }
      shopifyId
    }
    patterns: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: { key: { eq: "type" }, value: { eq: "Wallcoverings" } }
        }
      }
      sort: { order: ASC, fields: [handle] }
    ) {
      edges {
        node {
          id
          title
          description
          products {
            shopifyId
            id
          }
          shopifyId
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }

    colorTagGroup: strapiTagGroup(slug: { eq: "color" }) {
      __typename
      id
      strapiId
      name
      tags {
        __typename
        name
        id
        slug
      }
    }

    filters: storyblokEntry(
      full_slug: { eq: "filters/default-paint-filters" }
    ) {
      content
      id
      slug
      uuid
      internalId
    }

    filterCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            namespace: { eq: "filters" }
            key: { eq: "category" }
            value: { eq: "true" }
          }
        }
      }
    ) {
      edges {
        node {
          title
          handle
          products {
            id
            shopifyId
          }
        }
      }
    }
  }
`;
