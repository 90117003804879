import React, { useState } from 'react';
import kebabCase from 'lodash.kebabcase';
import { Accordion, AccordionPanel, Box } from 'grommet';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Close } from 'grommet-icons';
import { useLocation } from '@reach/router';

import SmartLink from '../SmartLink';
import ColorFilterPanelGroup from '../Color/ColorFilterPanelGroup';
import TextTitle from '../TextTitle';
import { toggleGenericFilter } from '../../lib/filters/filterRouteHelpers';
import ListPaintFilters from '../Product/ListPaintFilters';

const StyledAccordionPanel = styled(AccordionPanel)`
  padding: 0.6rem 0;
`;

const StyledListPaintFilters = styled(ListPaintFilters)`
  .list-paint-filter-item[data-is-active='true'] {
    font-weight: 500;
  }
`;

type WallcoveringsFiltersProps = {
  colorFilters: any[];
  decodedQuery: Record<string, any>;
  activeColorTags: any[];
  activeDesignerTags: any[];
  activeSizeTags: any[];
  designers: string[];
  typeFilters: any[];
  sizes: { label: string; slug: string }[];
  filterCollections: any[];
  activeFilterCollections: string[];
  activePanels?: number[];
  setActivePanels: (panels: number[]) => void;
  onFiltersChanged?: () => void;
};

interface TagFilter {
  component: 'Filter' | 'Sort';
  hexValue: {
    color: string;
    _uid: string;
    plugin: 'native-color-picker';
  };
  name: string;
  slug: string;
  _editable: string;
  _uid: string;
}

export function WallcoveringsFilters({
  colorFilters = [],
  decodedQuery = { filter: [] },
  activeColorTags = [],
  activeSizeTags = [],
  activeDesignerTags = [],
  activeFilterCollections,
  activePanels = [0],
  setActivePanels,
  designers = [],
  typeFilters = [],
  filterCollections = [],
  onFiltersChanged,
  sizes = [],
}: WallcoveringsFiltersProps) {
  const location = useLocation() ?? {};
  const activeColorFilters = colorFilters.filter((x) => {
    const filterParam = decodedQuery.filter || [];
    return filterParam.indexOf(x.slug) > -1;
  });

  function handleTagFilter(tag: TagFilter) {
    const newRoute = toggleGenericFilter(tag, location, {
      removeNamespace: false,
    });

    navigate(`${location.pathname}?${newRoute}`, {
      replace: true,
      state: {
        preventScroll: true,
      },
    });

    onFiltersChanged?.();
  }

  return (
    <Accordion
      multiple
      gap="none"
      activeIndex={activePanels}
      onActive={setActivePanels}
    >
      {filterCollections.length > 0 && (
        <StyledAccordionPanel
          label={
            <TextTitle>
              Style &amp; Pattern {activeCounter(activeFilterCollections)}
            </TextTitle>
          }
        >
          <Box gap="small" pad={{ vertical: 'small' }}>
            {filterCollections
              .sort((a, b) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map((collection) => (
                <FilterItem
                  key={collection.handle}
                  slug={collection.handle}
                  label={collection.title}
                  filterParam={decodedQuery.filter}
                />
              ))}
          </Box>
        </StyledAccordionPanel>
      )}
      <StyledAccordionPanel
        label={<TextTitle>Color {activeCounter(activeColorFilters)}</TextTitle>}
      >
        <StyledListPaintFilters
          filters={colorFilters}
          activeFilters={activeColorFilters}
          onTagFilter={handleTagFilter}
          labelKey="name"
          valueKey="slug"
          margin={{ top: 'small' }}
          withColor
          fontSize="small"
          itemSpacing="small"
        />
      </StyledAccordionPanel>
      {designers.length > 1 && (
        <StyledAccordionPanel
          label={
            <TextTitle>Designer {activeCounter(activeDesignerTags)}</TextTitle>
          }
        >
          <Box gap="small" pad={{ vertical: 'small' }}>
            {designers.map((designer) => (
              <FilterItem
                key={designer}
                slug={designer}
                label={designer}
                filterParam={decodedQuery.filter}
              />
            ))}
          </Box>
        </StyledAccordionPanel>
      )}
      <StyledAccordionPanel
        label={<TextTitle>Size {activeCounter(activeSizeTags)}</TextTitle>}
      >
        <Box gap="small" pad={{ vertical: 'small' }}>
          {sizes.map((size) => (
            <FilterItem
              key={size.slug}
              slug={size.slug}
              label={size.label}
              filterParam={decodedQuery.filter}
            />
          ))}
        </Box>
      </StyledAccordionPanel>
      {typeFilters.length > 1 && (
        <StyledAccordionPanel label={<TextTitle>Type</TextTitle>}>
          <Box gap="small" pad={{ vertical: 'small' }}>
            {typeFilters.map((wcType) => (
              <FilterItem
                key={wcType.slug}
                slug={wcType.slug}
                label={wcType.name}
                filterParam={decodedQuery.filter}
              />
            ))}
          </Box>
        </StyledAccordionPanel>
      )}
    </Accordion>
  );
}

function activeCounter(activeFilters: any[]) {
  return activeFilters.length > 0 && `(${activeFilters.length})`;
}

function FilterItem({ slug, label, filterParam }) {
  const location = useLocation();
  function isActiveFilter(slug: string) {
    return (
      (filterParam || []).indexOf(slug) > -1 ||
      (filterParam || []).indexOf(slug.replace(' ', '-')) > -1
    );
  }

  return (
    <Box
      key={slug}
      direction="row"
      fill="horizontal"
      pad={{ horizontal: 'medium', vertical: 'xsmall' }}
      background={isActiveFilter(slug) ? '#FAFAFA' : 'none'}
    >
      <SmartLink
        style={{
          display: 'block',
          width: '100%',
          fontWeight: isActiveFilter(slug) ? 500 : 400,
        }}
        plain
        to={`${location.pathname}?${toggleGenericFilter({ slug }, location, {
          removeNamespace: false,
        })}`}
        size="small"
      >
        <Box
          direction="row"
          justify="between"
          align="center"
          fill="horizontal"
          gap="small"
        >
          {label}
          {isActiveFilter(slug) && <Close color="black" size={'small'} />}
        </Box>
      </SmartLink>
    </Box>
  );
}
