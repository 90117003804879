import React, { memo, useContext, useMemo, useRef } from 'react';
import queryString from 'query-string';
import {
  Box,
  Button,
  Grid,
  Heading,
  ResponsiveContext,
  ThemeContext,
} from 'grommet';
import { useLocation } from '@reach/router';
import { decodeQueryParams, StringParam } from 'serialize-query-params';
import { Close } from 'grommet-icons';

import styled from 'styled-components';

import WallcoveringsPatternGridItem from './WallcoveringsPatternGridItem';
import MarkdownContent from '../MarkdownContent';
import useMobile from '../useMobile';
import CollectionProductCount from '../Collection/CollectionProductCount';
import ResponsiveGrid from '../ResponsiveGrid';
import { compact, intersection, titleCase, uniq } from '../../lib/nodash';
import { CommaArrayParam } from '../../lib/customParams';

import SmartLink from '../SmartLink';
import {
  filterWallcoveringsSizeTags,
  isMemo,
  isPeelAndStick,
} from '../../lib/product';
import useMenuOffset from '../useMenuOffset';

import TextTitle from '../TextTitle';
import { WallcoveringsFilters } from './WallcoveringsFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveFilterCategories,
  toggleFilterPanel,
} from '../../state/wallcoverings/wallcoveringsSlice';
import { WALLCOVERINGS_SIZE_TAGS } from '../../lib/constants';

const StyledGrid = styled(Grid)`
  grid-template-columns: unset;
  grid-template-rows: auto 1fr;

  .grid-item {
    min-height: calc(
      100vw / 2 + ${(p) => `${parseInt(p.theme.global.edgeSize.small) * 2}px`}
    );
  }

  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    .grid-item {
      min-height: calc(
        100vw / 2 + ${(p) => `${parseInt(p.theme.global.edgeSize.small) * 2}px`}
      );
    }
  }

  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.medsmall.value}px)`} {
    .grid-item {
      min-height: calc(
        100vw / 3 + ${(p) => `${parseInt(p.theme.global.edgeSize.small) * 2}px`}
      );
    }
  }

  &[data-filter-panel-active='true'] {
    grid-template-columns: 35vw auto;

    .grid-item {
      min-height: calc(
        100vw - 35vw + ${(p) => `${parseInt(p.theme.global.edgeSize.small)}px`}
      );
    }

    @media only screen ${(p) =>
        `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
      grid-template-columns: 20vw auto;

      .grid-item {
        min-height: calc(
          (100vw - 20vw) / 2 +
            ${(p) => `${parseInt(p.theme.global.edgeSize.small) * 2}px`}
        );
      }
    }

    @media only screen ${(p) =>
        `and (min-width: ${p.theme.global.breakpoints.medsmall.value}px)`} {
      grid-template-columns: 20vw auto;

      .grid-item {
        min-height: calc(
          (100vw - 20vw) / 3 +
            ${(p) => `${parseInt(p.theme.global.edgeSize.small) * 3}px`}
        );
      }
    }
  }
`;

const GridItem = Box;

const FilterPanelBox = styled(Box)`
  position: sticky;
  z-index: 100;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: auto;
  background: white;
  transition: top 0.2s;

  &[data-filter-panel-active='true'] {
    position: sticky;
    top: 0;
    width: 100%;
    overflow: auto;
    overscrollbehaviory: contain;
    height: 100vh;
  }

  body:has(.headroom--pinned) & {
    top: ${(p) => p.yOffset - 2}px;
  }

  body:has(.headroom--pinned) &[data-filter-panel-active='true'] {
    height: calc(100vh - ${(p) => p.yOffset}px);
  }
`;

const ENABLE_TYPE_FILTERS = false;

interface WallcoveringsCollectionProps {
  path: string;
  children: number;
  collection: { products: any[]; title: string; descriptionHtml: string };
  patterns: any[];
  colors: { name: string; id: string; slug: string }[];
  colorFilters: any[];
  filterCollections: any[];
}

const WallcoveringsCollection = ({
  children,
  collection,
  patterns,
  colors,
  colorFilters,
  filterCollections,
}: WallcoveringsCollectionProps) => {
  const firstRef = useRef<HTMLDivElement>(null);
  const activeFilterCategories = useSelector(
    (state) => state.wallcoverings.activeFilterCategories ?? []
  );
  const filterPanelActive = useSelector(
    (state) => state.wallcoverings.filterPanelActive ?? false
  );
  const dispatch = useDispatch();
  const { offsetFromTop } = useMenuOffset();
  const { products } = collection;
  const designers = uniq(
    compact(products.map((x) => x.designer?.value))
  ).sort();
  const sizes = uniq(
    compact(products.map((x) => x.tags).flat()).filter(
      filterWallcoveringsSizeTags
    )
  )
    .sort((a, b) => {
      return (
        WALLCOVERINGS_SIZE_TAGS.indexOf(a) - WALLCOVERINGS_SIZE_TAGS.indexOf(b)
      );
    })
    .map((tag) => ({
      label: titleCase(tag.replace('-', ' ')),
      slug: tag,
    }));

  const hasPeelAndStick = products.some((x) =>
    x.variants.some((v) => isPeelAndStick(v))
  );
  const typeFilters = ENABLE_TYPE_FILTERS
    ? [{ name: 'Traditional', slug: 'traditional' }].concat(
        hasPeelAndStick
          ? [{ name: 'Peel & Stick', slug: 'peel-and-stick' }]
          : []
      )
    : [];

  const size = useContext(ResponsiveContext);
  const isMobile = useMobile();
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const searchString = location.search;

  const colCount = getColCount(size, filterPanelActive);

  const imageSizes = `(min-width: ${
    theme.global.breakpoints.medium.value
  }px) calc(${100 / 3}vw - ${theme.global.edgeSize.large} - ${
    theme.global.edgeSize.large
  }), (min-width: ${theme.global.breakpoints.medsmall.value}px) calc(33vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}),
    (min-width: ${theme.global.breakpoints.small.value}px) calc(50vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}), 100vw`;

  // Parse filters from URL...
  const searchParams = useMemo(
    () => queryString.parse(searchString),
    [searchString]
  );

  const decodedQuery = useMemo(
    () =>
      decodeQueryParams(
        {
          filter: CommaArrayParam,
          sort: StringParam,
        },
        searchParams
      ),
    [searchParams, decodeQueryParams, CommaArrayParam, StringParam]
  );

  const filterParam = decodedQuery.filter || [];
  const activeDesignerTags = filterParam.filter(
    (x) => designers.indexOf(x) > -1
  );
  const activeFilterCollectionTags = filterParam.filter((x) =>
    filterCollections.map((c) => c.handle).includes(x)
  );
  const activeFilterCollections = filterCollections.filter((x) =>
    activeFilterCollectionTags.includes(x.handle)
  );
  const activeColorTags = filterParam.filter(
    (x) => colors.map((c) => c.slug).indexOf(x) > -1
  );
  const activeSizeTags = filterParam.filter(
    (x) => sizes.map((c) => c.slug).indexOf(x) > -1
  );

  const setActivePanels = (active: number[]) => {
    dispatch(setActiveFilterCategories(active));
  };

  const filteredProducts = useMemo(() => {
    if (filterParam.length === 0) {
      return products;
    }

    return products.filter(({ tags, designer, variants, shopifyId }) => {
      const typeParams = filterParam.filter(
        (x) =>
          compact(typeFilters)
            .map((t) => t.slug)
            .indexOf(x) > -1
      );

      const typeMatch = getTypeMatch(typeParams, variants);

      const collectionMatch =
        activeFilterCollections.length > 0
          ? activeFilterCollections.some((c) =>
              c.products
                .map((p) => {
                  return p.shopifyId;
                })
                .includes(shopifyId)
            )
          : true;

      const designerMatch =
        activeDesignerTags.length > 0
          ? activeDesignerTags.includes(designer?.value)
          : true;
      const sizeMatch =
        activeSizeTags.length > 0
          ? intersection(tags, activeSizeTags).length > 0
          : true;
      const colorMatch =
        activeColorTags.length > 0
          ? intersection(tags, activeColorTags).length > 0
          : true;

      return (
        designerMatch && colorMatch && typeMatch && sizeMatch && collectionMatch
      );
    });
  }, [decodedQuery.filter, products, activeColorTags, activeDesignerTags]);

  return (
    <Box>
      {(collection.title || collection.descriptionHtml) && (
        <Box
          pad={{ horizontal: 'xlarge', vertical: 'medium', top: 'large' }}
          style={{ zIndex: 1 }}
          margin={{ top: 'xlarge' }}
          direction="row-responsive"
          border={{ side: 'bottom', size: 'small' }}
        >
          <Box>
            {collection.title && (
              <Heading style={{ maxWidth: '11em', marginTop: 0 }} level={1}>
                {collection.title}
              </Heading>
            )}
          </Box>
          <Box>
            {collection.descriptionHtml && (
              <MarkdownContent>{collection.descriptionHtml}</MarkdownContent>
            )}
          </Box>
        </Box>
      )}
      <StyledGrid data-filter-panel-active={filterPanelActive}>
        <FilterPanelBox
          data-filter-panel-active={filterPanelActive}
          yOffset={offsetFromTop}
        >
          <Grid style={{ gridTemplateRows: 'auto 1fr' }}>
            <Box
              gap="medium"
              direction="row"
              justify={filterPanelActive ? 'between' : 'stretch'}
              pad={{
                horizontal: 'medium',
                vertical: 'medium',
              }}
            >
              <Button secondary onClick={() => dispatch(toggleFilterPanel())}>
                Filter {filterPanelActive ? '-' : '+'}
              </Button>
              {(decodedQuery.filter ?? []).length > 0 && !isMobile && (
                <SmartLink
                  plain
                  href={location.pathname}
                  to={location.pathname}
                  style={{ alignContent: 'center' }}
                >
                  <TextTitle
                    color="dark-2"
                    size="xsmall"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.4rem',
                    }}
                  >
                    Clear {filterParam.length} <Close size="small" />{' '}
                  </TextTitle>
                </SmartLink>
              )}
            </Box>

            {filterPanelActive && (
              <Box pad={{ horizontal: 'medium' }}>
                <WallcoveringsFilters
                  colorFilters={colorFilters}
                  decodedQuery={decodedQuery}
                  activeColorTags={activeColorTags}
                  activeDesignerTags={activeDesignerTags}
                  activeSizeTags={activeSizeTags}
                  designers={designers}
                  typeFilters={typeFilters}
                  sizes={sizes}
                  filterCollections={filterCollections}
                  activeFilterCollections={activeFilterCollections}
                  activePanels={activeFilterCategories}
                  setActivePanels={setActivePanels}
                  onFiltersChanged={() => {
                    firstRef.current?.scrollIntoView({
                      block: 'start',
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
        </FilterPanelBox>

        <Box
          fill="horizontal"
          border={filterPanelActive && { side: 'left', size: 'small' }}
          pad={{ top: 'medium' }}
        >
          <div ref={firstRef} style={{ scrollMarginTop: `20px` }} />
          <ResponsiveGrid
            disableMobile={true}
            minRowHeight="48vh"
            gap={isMobile ? '8vw' : '40px'}
            fill="horizontal"
            columns={{ count: colCount, size: 'auto' }}
            mobileColumns={{ count: colCount, size: 'auto' }}
            pad={{ horizontal: 'large', vertical: 'small' }}
          >
            {filteredProducts.map((x, i) => {
              const pattern = patterns.find((z) => {
                return (
                  z.products.map((p) => p.shopifyId).indexOf(x.shopifyId) > -1
                );
              });
              const altProductIds =
                pattern?.products?.map((p) => p.shopifyId) || [];
              const altProducts = products.filter(
                (p) => altProductIds.indexOf(p.shopifyId) > -1
              );
              return (
                <GridItem
                  key={x.shopifyId}
                  filterPanelActive={filterPanelActive}
                  className="grid-item"
                >
                  <WallcoveringsPatternGridItem
                    key={x.shopifyId}
                    altProducts={altProducts}
                    pattern={pattern}
                    product={x}
                    imageSizes={imageSizes}
                  />
                </GridItem>
              );
            })}
          </ResponsiveGrid>
          {children}
          <CollectionProductCount count={filteredProducts.length} />
        </Box>
      </StyledGrid>
    </Box>
  );
};

export default memo(WallcoveringsCollection);

function getTypeMatch(typeParams: (string | null)[], variants: any[]) {
  const hasPeelAndStick = variants.some((v) => isPeelAndStick(v));
  const hasTraditional = variants.some((v) => !isMemo(v) && !isPeelAndStick(v));

  if (typeParams.length > 0) {
    const peelAndStickMatch = typeParams.includes('peel-and-stick')
      ? hasPeelAndStick
      : true;
    const traditionalMatch = typeParams.includes('traditional')
      ? hasTraditional
      : true;

    return peelAndStickMatch && traditionalMatch;
  } else {
    return true;
  }
}

function getColCount(size: string, filterPanelActive: boolean) {
  if (size === 'small' && filterPanelActive) {
    return 1;
  }

  if (size === 'small') {
    return 2;
  }

  if (size === 'medsmall') {
    return 2;
  }

  if (size === 'medium') {
    return 3;
  }

  return 3;
}
